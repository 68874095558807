import Component from '../core/Component';
import Flickity from 'flickity'

export default class Gallery extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            content: '',
            next: '',
            prev: ''
        }
    }

    prepare() {
        this.carouselOptions()

        this.ref.next.addEventListener('click',::this.clickNext)

        this.ref.prev.addEventListener('click',:: this.clickPrev)
    }


    carouselOptions() {
        const element = this.ref.content;
        this.flickity = new Flickity(element, {
            draggable: ">1",
            pageDots: false,
            fade: true,
            prevNextButtons: false,
        });
    }

    clickNext() {
        this.flickity.next()
    }

    clickPrev() {
        this.flickity.previous()
    }
}