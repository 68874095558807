import Component from '../core/Component'
import Scroll, { scrollToElement } from '../services/Scroll'

const STATES = {
    PINNED: 'is-pinned'
}

export default class JobCTA extends Component {
    constructor(element) {
        super(element)

        this.state = {
            start: 0,
            end: 0,
            pinned: false
        }
    }

    prepare() {
        this.element.addEventListener('click', this.handleClick)

        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)
        this.resize()
    }

    destroy() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleClick = event => {
        event.preventDefault()

        const target = document.getElementById(this.element.querySelector('a').href.split('#')[1])

        if (target) {
            scrollToElement(target, {
                offset: 80
            })
        }
    }

    handleResize = () => {
        this.resize()
    }

    handleScroll = ({ offset }) => {
        this.render(offset.y)
    }

    resize() {
        const startElement = document.getElementById('job-cta-start')
        const endElement = document.getElementById('job-cta-end')
        const scroll = Scroll.getScroll().y
        const box = this.element.getBoundingClientRect()
        const offset = box.top + box.height
        this.state.start = (startElement?.getBoundingClientRect()?.top ?? 0) + scroll - offset
        this.state.end = (endElement?.getBoundingClientRect()?.top ?? 0) + scroll - offset
        this.render(scroll)
    }

    render(y) {
        if (y > this.state.start && y < this.state.end && !this.state.pinned) {
            this.state.pinned = true
            this.element.classList.add(STATES.PINNED)
        }

        if ((y < this.state.start || y > this.state.end) && this.state.pinned) {
            this.state.pinned = false
            this.element.classList.remove(STATES.PINNED)
        }
    }

}
