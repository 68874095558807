import Component from '../core/Component';
import { queries, CMS } from '../core/config';
import { disableScrolling, enableScrolling } from '../services/Viewport/scroll';
import enquire from 'enquire.js';

export default class Tabs extends Component {
    constructor(element) {
        super(element);

         this.ref = {
            links: [],
            items: [],
            closes: [],
            contentWrap: ""
         }

         this.enquireHandler = {
             match: ::this._match,
             unmatch: ::this._unmatch,
         }

        this.isDesktop = true;
    }

    // async require() {
    //    this.throttle = await import('lodash/throttle');
    // }

    prepare() {
        if (CMS) {
            return
        }

        this.ref.links.forEach(link => {
            link.addEventListener("click",::this.handleClick)
        });

        this.ref.closes.forEach(link => {
            link.addEventListener("click",::this.hide)
        });

        this.setHeight(this.ref.items[0])

        enquire.register(queries.smallMax, this.enquireHandler);

        this.updateTab(window.location.hash)
    }

    destroy() {
        enquire.register(queries.smallMax, this.enquireHandler);
    }

    handleClick(e) {
        e.preventDefault()

        this.target_id = e.currentTarget.hash

        this.show(this.target_id)
    }

    show(id) {
        const CURRENT_LINK = this.element.querySelector("a[href='"+id+"']")
        const TARGET_ELEMENT = this.element.querySelector(id)

        this.ref.links.forEach(link => {
            link.classList.remove("is-active")
        });

        this.ref.items.forEach(content => {
            content.classList.remove("is-active")
        });

        CURRENT_LINK.classList.add("is-active")
        TARGET_ELEMENT.classList.add("is-active")

        if (this.isDesktop) {
            this.setHeight(TARGET_ELEMENT)
        } else {
            disableScrolling()
        }
    }

    setHeight(element) {
        this.height = element.offsetHeight
        this.ref.contentWrap.style.height = this.height + "px"
    }

    removeHeight() {
        this.ref.contentWrap.removeAttribute("style")
    }

    hide(e) {
        if (e) {
            e.preventDefault()
        }

        this.ref.items.forEach(content => {
            content.classList.remove("is-active")
        });

        enableScrolling()
    }

    updateTab(hash) {
        let ids = this.ref.links.map((link)=>{
            return link.hash
        })

        if (ids.indexOf(hash) !== -1) {
            this.show(hash)
        }
    }

    _match() {
        this.hide()
        this.isDesktop = false;
        this.removeHeight()
    }

    _unmatch() {
        this.ref.links.forEach((link,index) => {
            if (link.classList.contains("is-active")) {
                this.ref.items[index].classList.add("is-active")
                this.setHeight(this.ref.items[index])
            }
        });
        this.isDesktop = true;
        enableScrolling()
    }
}