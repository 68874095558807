import Component from '../core/Component';
import Flickity from 'flickity'

export default class Carousel extends Component {
    constructor(element) {
        super(element);

        // this.ref = {
        //    test: null,
        //    items: [],
        // }
    }

    prepare() {
        this.flickity = new Flickity(this.element, {
            draggable: ">1",
            pageDots: false,
            prevNextButtons: false,
            cellAlign: "left"
        });
    }
}