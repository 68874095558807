// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'

// project
import Alert from './Alert'
import Animation from './Animation'
import Carousel from './Carousel'
import ContactMap from './ContactMap'
import Gallery from './Gallery'
import GasMap from './GasMap'
import Filter from './Filter'
import FilterMap from './FilterMap'
import InputUpload from './InputUpload'
import JobCTA from './JobCTA'
import FormNewsletter from './FormNewsletter'
import NavOpener from './NavOpener'
import Notification from './Notification'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import PageEdit from './PageEdit'
import Select from './Select'
import Slider from './Slider'
import Tabs from './Tabs'

const Components = {
    Alert,
    Antispam,
    Animation,
    // CookieBubble,
    Carousel,
    ContactMap,
    Gallery,
    GasMap,
    Filter,
    FilterMap,
    FormNewsletter,
    InputUpload,
    JobCTA,
    NavOpener,
    Notification,
    Modal,
    ModalOpener,
    PageEdit,
    Select,
    Slider,
    Tabs
}

export default Components
